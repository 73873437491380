<template>
  <div class="select-icons">
    <v-subheader
      light
      class="v-label v-label--active"
      style="margin-bottom: -10px;"
    >
      <v-icon>{{ icon }}</v-icon>
      {{ $t(label) }}
      <v-layout justify-end>
        <v-tooltip bottom v-if="$t(description)">
          <template v-slot:activator="{ on }">
            <v-icon class="ml-12" slot="activator" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>{{ $t(description) }}</span>
        </v-tooltip>
      </v-layout>
    </v-subheader>
    <v-dialog v-model="dialog" scrollable max-width="400" style="max-height:400px;">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          dark
          v-on="on"
        >
          <v-icon v-if="value">{{ value }}</v-icon>
          <div v-else>{{$t('fields.selectIcons')}}</div>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <v-text-field
            v-model="search"
            :label="$t('crud.searchGeneric')"
            :color="color"
            append-icon="search"
          >
          </v-text-field>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(icon, index) in icons"
              :key="index"
              cols="2"
            >
            <v-icon
              v-on:click="toggleIcon(icon)"
              class="border"
              :class="{selected: selectedValue === icon}"
            >
              {{ icon }}
            </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary" text v-on:click="selectedValue = value; dialog = false">
            {{$t('crud.cancel')}}
          </v-btn>
          <v-btn color="primary" text v-on:click="value = selectedValue; dialog = false">
            {{$t('crud.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseTextInput from '@/mixins/baseTextInput';
import listIconsLight from '../../../assets/icons.light.json';

export default {
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.selectIcons',
    },
    description: {
      type: String,
      default: 'fields.selectIconsTooltip',
    },
  },
  data: () => ({
    value: '',
    dialog: false,
    search: '',
    selectedValue: '',
  }),
  computed: {
    /**
     * The first hundred icons matching the search
     *
     * @return {Array}
     */
    icons() {
      const regex = new RegExp(this.search, 'g');
      return listIconsLight.filter((icon) => icon.match(regex)).slice(0, 100);
    },
  },
  created() {
    if (this.value) {
      this.selectedValue = '';
    }
  },
  methods: {
    /**
     * Cancel selection so restore selectedValue
     * Close dialog
     */
    cancel() {
      this.dialog = false;
      this.selectedValue = this.value;
    },
    /**
     * Save selection so update value with selectedValue
     * Close dialog
     */
    save() {
      this.dialog = false;
      this.value = this.selectedValue;
    },
    /**
     * Update selectedValue with name of clicked icon
     * if clicked icon is currently selected clear selectedValue
     *
     * @param {string} icon name of clicked icon
     */
    toggleIcon(icon = '') {
      if (this.readonly !== true) {
        if (this.selectedValue === icon) {
          this.selectedValue = '';
        } else {
          this.selectedValue = icon;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.select-icons .theme--light {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.border {
  border: 1px solid white;
  &.selected {
    border: 1px solid black;
  }
}
</style>
